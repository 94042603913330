import { getValue } from '../../components/nominex/Form/formUtils';

const _ = require('lodash');
function pick(obj, keys) {
	return Object.fromEntries(
		Object.entries(obj).filter(([key, value]) => {
			return !keys.includes(key);
		})
	);
}
const validaAtributo = (atributo, valor, texto) => {
	let atributoId = JSON.parse(atributo.id_atributo);
	let decimales = 0;
	let enteros = 0;
	if (!texto) {
		let valorSplit = valor.split('.');
		decimales = valorSplit[1] ? valorSplit[1].length : 0;
		enteros = valorSplit[0] ? valorSplit[0].length : 0;
	} else {
		enteros = valor.length;
	}
	switch (atributoId.slug) {
		case 'longitud_maxima':
			return enteros <= atributo.valor;
		case 'longitud_minima':
			// return atributo.valor>=enteros;
			return true
		case 'numero_decimales':
			return decimales <= atributo.valor;
		default:
			return true
	}
}
function inputValue(value, tipoEntrada) {
	let tipoDatoEntrada = JSON.parse(tipoEntrada.tipo_dato_entrada);
	// let detalleNominaConceptoAtributos = JSON.parse(tipoEntrada.detalleNominaConceptoAtributos.id_atributo)
	let listAtributos = tipoEntrada.detalleNominaConceptoAtributos;
	switch (tipoDatoEntrada.id) {
		case 'texto':
			if (listAtributos && listAtributos.length > 0) {
				let continuar = true;
				listAtributos.map((atributo) => {
					if (continuar) {
						continuar = validaAtributo(atributo, value, true);
					}
				});
				return continuar;
			} else
				return true;
		case 'numerico':
			if (!isNaN(value)) {
				if (listAtributos && listAtributos.length > 0) {
					let continuar = true;
					listAtributos.map((atributo) => {
						if (continuar) {
							continuar = validaAtributo(atributo, value, false);
						}
					});
					return continuar;
				} else return true
			} else {
				return false
			}
		case 'numerico_con_decimales':
			if (!isNaN(value)) {
				if (listAtributos && listAtributos.length > 0) {
					let continuar = true;
					listAtributos.map((atributo) => {
						if (continuar) {
							continuar = validaAtributo(atributo, value, false);
						}
					});
					return continuar;
				} else return true
			} else {
				return false
			}
		case 'fecha':
			const validDate = new Date(value);
			return validDate > 0;
		default:
			return false;
	}
}
function replaceTags(str,regex,obj){
	const foundMatches = [...str.matchAll(regex)];
	if(foundMatches && foundMatches.length>0){
		foundMatches.map((foundMatch,index) => {
			str = str.replaceAll(foundMatch[0],getValue(foundMatch[1], obj))
		});
	}
	return str;
}
function removeTime(date = new Date()) {
	return new Date(
		date.getFullYear(),
		date.getMonth(),
		date.getDate()
	);
}

function getAddressComplete(data){
	let response = "No Data";
	if(data){
		response = "";
		if(data.calle){
			response += `${data.calle} `;
		}
		if(data.noExt){
			response += `${data.noExt} `;
		}
		if(data.noInt){
			response += `Int. ${data.noInt} `;
		}
		if(response)response += ", ";
		if(data.colonia && data.colonia.title){
			response += `${data.colonia.title} `;
		}
		if(response)response += ", ";
		if(data.municipio && data.municipio.title){
			response += `${data.municipio.title} `;
		}
		if(response)response += ", ";
		if(data.codigoPostal && data.codigoPostal.title){
			response += `${data.codigoPostal.title} `;
		}
		if(data.estado && data.estado.title){
			response += `${data.estado.title} `;
		}
		if(data.pais && data.pais.title){
			response += `${data.pais.title} `;
		}
		if(data.ciudad){
			if(response)response += ", ";
			response += `${data.ciudad} `;
		}
		if(!response){
			response = "No Address Data";
		}
	}
	return response;
}

function converNumberToCurrency(props){
	
	const {value, format} = props;
	let currencyConfig = null;
	switch(format){
		case "en-IN":
			currencyConfig = Intl.NumberFormat('en-IN');
			break;
		case "en-DE":
			currencyConfig = Intl.NumberFormat('en-DE');
			break;
		default:
			currencyConfig = new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency: 'USD',
			});
			break;
	}
	
	return currencyConfig.format(value);
}

const normalizeToSearch = (str) => {
	return str ? str
		.normalize("NFD")
		.replace(/[\u0300-\u036f]/g, "")
		.toLowerCase(): "";
};

const importAll = (r) => {
	return r.keys().map(r);
}

const isEmptyValue = (value) => {
	if (value === null || value === undefined || value === '') {
		return true;
	}
	return false;
}

export { converNumberToCurrency, getAddressComplete, importAll, inputValue, normalizeToSearch, pick, removeTime, replaceTags, isEmptyValue };

